<template>
  <div>
    <v-container>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ประเภทวิชา : จากระบบ ศธ.02"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="8" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>

            <v-col cols="12" lg="4" class="text-right">
              <v-btn
                block
                large
                right
                depressed
                color="primary"
                @click.native="subject_typeAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>

              <v-btn
                block
                large
                right
                depressed
                color="info"
                @click.native="subject_typeQueryAll()"
              >
                <v-icon>mdi-content-paste</v-icon>แสดงรายการ
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="subject_types"
          :search="search"
        >
          <template v-slot:item.subject_typeStatus="{ item }">
            <v-icon v-if="item.subject_typeStatus === '1'" color="green">
              mdi-lightbulb-on
            </v-icon>

            <v-icon v-else>
              mdi-lightbulb
            </v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="subject_typeEdit(item.subject_typeAI)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              color="red"
              @click.stop="subject_typeDelete(item.subject_typeAI)"
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addsubject_typedialog  -->
      <v-layout row justify-center>
        <v-dialog v-model="addsubject_typedialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลประเภทวิชา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addsubject_typeform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field
                        label="รหัสประเภทวิชา"
                        v-model="addsubject_type.subject_typeID"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-text-field
                        label="ชื่อประเภทวิชา"
                        v-model="addsubject_type.subject_typeName"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-text-field
                        label="ปี"
                        v-model="addsubject_type.subject_typeYear"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-switch
                        v-model="addsubject_type.subject_typeStatus_sw"
                        label="เปิดใช้งานประเภทวิชา"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addsubject_typedialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addsubject_typeSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletesubject_typedialog -->
      <v-layout>
        <v-dialog v-model="deletesubject_typedialog" persistent max-width="80%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลประเภทวิชา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deletesubject_typeform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูลประเภทวิชา
                          {{ editsubject_type.subject_typeName }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deletesubject_typedialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletesubject_typeSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editsubject_typedialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editsubject_typedialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลสาขาวิชา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editsubject_typeform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field
                        label="รหัสประเภทวิชา"
                        v-model="editsubject_type.subject_typeID"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-text-field
                        label="ชื่อประเภทวิชา"
                        v-model="editsubject_type.subject_typeName"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-text-field
                        label="ปี"
                        v-model="editsubject_type.subject_typeYear"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md6>
                      <v-switch
                        v-model="editsubject_type.subject_typeStatus_sw"
                        label="เปิดใช้งานประเภทวิชา"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="editsubject_typedialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editsubject_typeSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model man_powerCSVdialog -->
      <v-layout>
        <v-dialog v-model="man_powerCSVdialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลสาขาวิชา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form ref="man_powerCSVdialogform" lazy-validation>
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-btn
                            block
                            large
                            color="info"
                            to="/admin/subject_type_import/"
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกแบบฟอร์มบันทึกข้อมูล</v-btn
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <h2>
                            <v-file-input
                              v-model="file2"
                              accept=".csv"
                              name="csvFiles"
                              color="deep-purple accent-4"
                              counter
                              label="ไฟล์ .csv"
                              placeholder="ไฟล์ CSV สาขาวิชา"
                              prepend-icon="mdi-paperclip"
                              outlined
                              rounded
                              :show-size="1000"
                            >
                              <template v-slot:selection="{ index, text }">
                                <v-chip
                                  v-if="index < 2"
                                  color="deep-purple accent-4"
                                  dark
                                  label
                                  small
                                >
                                  {{ text }}
                                </v-chip>

                                <span
                                  v-else-if="index === 2"
                                  class="text-overline grey--text text--darken-3 mx-2"
                                >
                                  +{{ files.length - 2 }} File(s)
                                </span>
                              </template>
                            </v-file-input>
                          </h2>

                          <v-btn
                            class="pa-2"
                            block
                            large
                            color="green"
                            @click.stop="csvManpowerSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="man_powerCSVdialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addsubject_typedialog: false,
      editsubject_typedialog: false,
      deletesubject_typedialog: false,
      man_powerCSVdialog: false,
      file2: null,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      subject_types: [],
      addsubject_type: {},
      editsubject_type: {},
      search: "",
      subject_typeNameSelect: "",
      pagination: {},
      headers: [
        { text: "รหัสประเภท", align: "center", value: "subject_typeID" },
        { text: "ประเภทวิชา", align: "left", value: "subject_typeName" },
        { text: "ปี", align: "center", value: "subject_typeYear" },
        { text: "สถานะ", align: "center", value: "subject_typeStatus" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      college: {},
      provinces: [],
      prefectures: [],
      subject_typestatus: [],
      subject_typesGroupBranch: [],
      addid_subject_typeName: {},
      regions: [],
      region_ena: true,
      rate_work_level_classSelect: [
        { text: "ปวช.", value: "20" },
        { text: "ปวส.", value: "30" }
      ],
      subject_typeIDSelect: [
        { text: "ปวช.62", value: "ปวช.62" },
        { text: "ปวช.65", value: "ปวช.65" },
        { text: "ปวช.66", value: "ปวช.66" },
        { text: "ปวช.67", value: "ปวช.67" },
        { text: "ปวช.68", value: "ปวช.68" },
        { text: "ปวช.65", value: "ปวช.65" },
        { text: "ปวช.65", value: "ปวช.65" },
        { text: "ปวส.63", value: "ปวส.63" },
        { text: "ปวส.64", value: "ปวส.64" },
        { text: "ปวส.65", value: "ปวส.65" },
        { text: "ปวส.66", value: "ปวส.66" },
        { text: "ปวส.67", value: "ปวส.67" },
        { text: "ปวส.68", value: "ปวส.68" }
      ]
    };
  },
  async mounted() {
    this.subject_typeQueryAll();
  },
  methods: {
    async subject_typeUpdate() {
      if (this.$refs.updatesubject_typeform.validate()) {
        this.editsubject_type.ApiKey = this.ApiKey;
        this.editsubject_type.subject_typeName = this.subject_typeNameSelect;
        let result = await this.$http.post(
          "subject_type.updateIdtypeCourse.php",
          this.editsubject_type
        );
        if (result.data.status == true) {
          this.subject_type = result.data;
           Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.subject_typeQueryAll();
        } else {
           Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.subject_typeQueryAll();
      }
    },
    async subject_typeQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("subject_type.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.subject_types = result.data;
    },

    async subject_typeSearch() {
      this.loading = true;
      let result = await this.$http
        .post("subject_type.php", {
          ApiKey: this.ApiKey,
          subject_typeName: this.subject_typeNameSelect
        })
        .finally(() => (this.loading = false));
      this.subject_types = result.data;
      ;
    },

    async subject_typeGroupBranch() {
      this.loading = true;
      let result = await this.$http
        .post("subject_type.php", {
          ApiKey: this.ApiKey,
          showGroupType: "Ok"
        })
        .finally(() => (this.loading = false));
      this.subject_typesGroupBranch = result.data;
    },

    async subject_typeAdd() {
      this.addsubject_type = {};
      this.addsubject_typedialog = true;
    },
    async addsubject_typeSubmit() {
      if (this.$refs.addsubject_typeform.validate()) {
        this.addsubject_type.ApiKey = this.ApiKey;
        if (this.addsubject_type.subject_typeStatus_sw == true) {
          this.addsubject_type.subject_typeStatus = "1";
        } else {
          this.addsubject_type.subject_typeStatus = "0";
        }

        let result = await this.$http.post(
          "subject_type.insert.php",
          this.addsubject_type
        );
        if (result.data.status == true) {
          this.subject_type = result.data;
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "บันทึกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.subject_typeQueryAll();
        } else {
           Swal.fire({
            icon: "warning",
            title: "บันทึกข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.subject_typeQueryAll();
        }
        this.subject_typeQueryAll();
        this.addsubject_typedialog = false;
      }
    },
    async subject_typeEdit(subject_typeAI) {
      let result = await this.$http.post("subject_type.php", {
        ApiKey: this.ApiKey,
        subject_typeAI: subject_typeAI
      });
      this.editsubject_type = result.data;
      if (this.editsubject_type.subject_typeStatus == 1) {
        this.editsubject_type.subject_typeStatus_sw = true;
      } else {
        this.editsubject_type.subject_typeStatus = false;
      }

      this.editsubject_typedialog = true;
    },
    async editsubject_typeSubmit() {
      if (this.$refs.editsubject_typeform.validate()) {
        this.editsubject_type.ApiKey = this.ApiKey;
        if (this.editsubject_type.subject_typeStatus_sw == true) {
          this.editsubject_type.subject_typeStatus = "1";
        } else {
          this.editsubject_type.subject_typeStatus = "0";
        }

        let result = await this.$http.post(
          "subject_type.update.php",
          this.editsubject_type
        );
        if (result.data.status == true) {
          this.subject_type = result.data;
           Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.subject_typeQueryAll();
        } else {
           Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.subject_typeQueryAll();
        this.editsubject_typedialog = false;
      }
    },
    async subject_typeDelete(subject_typeAI) {
      let result = await this.$http.post("subject_type.php", {
        ApiKey: this.ApiKey,
        subject_typeAI: subject_typeAI
      });
      this.editsubject_type = result.data;
      this.deletesubject_typedialog = true;
    },
    async deletesubject_typeSubmit() {
      if (this.$refs.deletesubject_typeform.validate()) {
        this.editsubject_type.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "subject_type.delete.php",
          this.editsubject_type
        );
        ;
        if (result.data.status == true) {
          this.subject_type = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.subject_typeQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletesubject_typedialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "blue darken-4";
    }
  }
};
</script>
